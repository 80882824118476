import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService, ValidationService } from '@cue/admin-core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class TimeTableService implements ValidationService {
  public entity = CONSTANTS.entity.timeTable;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  isUniqueName(name: string, id: number): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  edit(id: number, value: any): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/' + id;
    return this.http.put(url, { data: value }).pipe(
      map((_) => ({ success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.error,
        }),
      ),
    );
  }

  delete(ids: number[]): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/delete';
    return this.http.post(url, ids).pipe(
      map((_) => ({ success: true })),
      catchError((errorData) => {
        console.log(errorData);
        return of({
          success: false,
          errorCode: errorData.status,
          errorDescription: errorData.title ?? errorData.message,
        });
      }),
    );
  }

  public setControlsTouchedRecursively(controls: { [key: string]: AbstractControl }): void {
    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        const item = controls[key] as AbstractControl;
        item.markAsTouched();
        item.markAsDirty();
        if (item.hasOwnProperty('controls')) {
          this.setControlsTouchedRecursively(item['controls']);
        }
      }
    }
  }
}
