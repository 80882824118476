import { Observable, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { CONSTANTS } from '@cue/admin-constants';
import { catchError } from 'rxjs/operators';
import { ProjectDescriptionUnit, ProjectInstance, UnitDropdown } from '../../pages/projects/models/projects.models';
import { Store } from '@ngrx/store';
import {
  addNewExpandedInstanceId,
  applyNewInstanceTemplate,
  expandedInstanceIds,
  instances,
  ProjectsRoot,
  setExpandedInstanceIds,
  setProjectInstances,
  addNewProjectInstance,
  deleteProjectInstance,
  projectInstanceChanged,
  projectInstanceUnitIdChanged,
  projectInstanceUnitParameterChanged,
} from '../../pages/projects/store';
import { UnitProductModel } from '@cue/admin-units';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService implements ValidationService {
  public entity = '/project';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private store: Store<ProjectsRoot>,
  ) {}

  getDetailById(id: string): Observable<any> {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as DataStateChangeEvent;

    const query = toDataSourceRequestString(filters);
    return this.http.post<any[]>(this.configService.value.apiURL + '/api/project/grid?&' + query, {}).pipe(
      map(
        (resp: any) =>
          resp.data.map((item) => ({
            ...item,
          }))[0],
      ),
    );
  }

  getInstancesTemplate(file: File): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/getInstancesTemplate';
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  getExistingInstancesTemplate(projectId: string): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/' + projectId + '/getExistingInstancesTemplate';
    return this.http.get(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  productModels(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/getProductModels';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  units(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/getUnits';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  getProjectInstances(projectId: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/' + projectId + '/instances';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  setNewProjectInstances(instances: ProjectInstance[]) {
    this.store.dispatch(setProjectInstances({ instances }));
  }

  addNewProjectInstance(instance: ProjectInstance) {
    this.store.dispatch(addNewProjectInstance({ instance }));
  }

  deleteProjectInstance(instanceId: string) {
    this.store.dispatch(deleteProjectInstance({ instanceId }));
  }

  applyNewInstanceTemplate(template: ProjectDescriptionUnit[], unitProductModels: UnitProductModel[]) {
    this.store.dispatch(applyNewInstanceTemplate({ template, unitProductModels }));
  }

  projectInstanceChanged(value: { name: string; developerMode: boolean }, instanceId: string) {
    this.store.dispatch(projectInstanceChanged({ value, instanceId }));
  }

  projectInstanceUnitIdChanged(unitId: number, instanceId: string, instanceUnitId: string, units: UnitDropdown[]) {
    this.store.dispatch(projectInstanceUnitIdChanged({ unitId, instanceId, instanceUnitId, units }));
  }

  projectInstanceUnitParameterChanged(
    value: string,
    instanceId: string,
    instanceUnitId: string,
    parameterName: string,
    useDefault: boolean,
  ) {
    this.store.dispatch(projectInstanceUnitParameterChanged({ value, instanceId, instanceUnitId, parameterName, useDefault }));
  }

  getStoredProjectInstances(): Observable<ProjectInstance[]> {
    return this.store.select(instances);
  }

  setExpandedInstanceIds(ids: string[]) {
    this.store.dispatch(setExpandedInstanceIds({ ids }));
  }

  getExpandedInstanceIds(): Observable<string[]> {
    return this.store.select(expandedInstanceIds);
  }

  addNewExpandedInstanceId(id: string) {
    this.store.dispatch(addNewExpandedInstanceId({ id }));
  }

  add(name: string, file: File, instances: ProjectInstance[]): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity;
    const fileCreated = new Date(file.lastModified);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileCreated', fileCreated.toISOString());
    formData.append('fileSize', file.size.toString());
    formData.append('name', name);
    formData.append('instancesString', JSON.stringify(instances));
    return this.http.post(url, formData).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  edit(projectId: string, name: string, file: File, instances: ProjectInstance[]): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/' + projectId;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('instancesString', JSON.stringify(instances));
    if (file) {
      const fileCreated = new Date(file.lastModified);
      formData.append('fileCreated', fileCreated.toISOString());
      formData.append('fileSize', file.size.toString());
    }
    return this.http.post(url, formData).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  delete(projectIds: string[]): Observable<any> {
    const url = this.configService.value.apiURL + CONSTANTS.api.prefix + this.entity + '/delete';
    return this.http.post(url, projectIds).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  isUniqueName(name: string, id: string): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/project/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  getProjectForDownload(id: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/' + id + '/downloadProjectFile';
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((x) =>
        of({
          success: false,
          errorCode: x.status,
          errorDescription: x.error,
        }),
      ),
    );
  }
}
