import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from './validation.service';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ResourceService implements ValidationService {
  public entity = CONSTANTS.entity.resource;
  public errorDescription = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  load(): Observable<any> {
    return this.getAction(this.entity + CONSTANTS.api.load);
  }

  loadData(): Observable<any> {
    const payload = {
      resource: true,
      assistUrl: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadDataForGrid(): Observable<any> {
    const payload = {
      resourceType: true,
      connectionAgent: true,
      area: true,
      timeTable: true,
    };
    return this.postAction(CONSTANTS.api.loadData, payload);
  }

  loadForDetail(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.loadForDetail, payload);
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.add, payload);
  }

  edit(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.edit, payload);
  }

  delete(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.delete, payload);
  }

  multiedit(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.multiEdit, payload);
  }

  copy(id: any, payload: any): Observable<any> {
    return this.postAction(this.entity + '/' + id + CONSTANTS.api.copy, payload);
  }

  test(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.test, payload);
  }

  multiTest(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.multiTest, payload);
  }

  testNewAction(
    payload: {
      apiConnectionAgentId: number;
      connectionAgentId: number;
      guid: string;
      originConnectionAgentId: number;
      username: string;
      displayName: string;
      email: string;
    }[],
  ): Observable<any> {
    return this.postAction(this.entity + '/testNewAction', payload);
  }

  import(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.import, payload);
  }

  importNew(payload: any): Observable<any> {
    return this.postAction(this.entity + '/new', payload);
  }

  refresh(payload: any): Observable<any> {
    return this.postAction(this.entity + CONSTANTS.api.refresh, payload);
  }

  resourcesWithoutArea(id: any): Observable<any> {
    return this.getAction(this.entity + '/' + id + '/resourcesWithoutArea');
  }

  isUniqueName(name: string, id: number): Observable<any> {
    if (name) {
      const url = this.configService.value.apiURL + '/api/resource/uniqueName/' + name;
      return this.http.post(url, { id });
    }
    return of({ success: true });
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;

    return this.http.post<any>(url, { data: payload }).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  generalDropdownData(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/generalDropdownData';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  workingHoursDropdownData(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/workingHoursDropdownData';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  picturesDropdownData(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/picturesDropdownData';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  settingsDropdownData(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + this.entity + '/settingsDropdownData';
    return this.http.get<any>(url).pipe(
      map((x) => ({ success: true, data: x })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: x.error });
      }),
    );
  }

  public setControlsTouchedRecursively(controls: { [key: string]: AbstractControl }): void {
    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        const item = controls[key] as AbstractControl;
        item.markAsTouched();
        item.markAsDirty();
        if (item.hasOwnProperty('controls')) {
          this.setControlsTouchedRecursively(item['controls']);
        }
      }
    }
  }
}
