import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';

@Component({
  selector: 'app-response-grid-dialog',
  templateUrl: './response-grid-dialog.component.html',
  styleUrls: ['./response-grid-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DialogWrapperComponent, TranslateModule, GridModule, NgIf],
  standalone: true,
})
export class ResponseGridDialogComponent implements OnInit {
  @Input({ required: true }) gridData;

  @Output() cancelDialog = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
